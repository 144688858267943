import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import React from "react";

import MMLastSection from "../components/AboveFooterSection";
import Layout from "../components/Layout";
import StructuredData from "../components/StructuredData";
import FormSection from "../components/pages/getInTouch/FormSection";
import Hero from "../components/pages/getInTouch/Hero";
import FeaturedBanner from "../components/pages/modemagic/FeaturedBanner";
import {useMediaQuery} from "../hooks/useMediaQuery";
import * as styles from "./etSummit/styles.module.scss";

const styler = new StyleExtractor(styles);

const GetInTouch = ({data: cmsData}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const seoData = cmsData.allSanitySeoMeta.nodes[0];
  const {lastSection} = cmsData.allSanityModemagicPage.nodes[0] ?? {};
  const {hero: heroData, form: formData} =
    cmsData.allSanityGetInTouchPage.nodes[0] ?? {};

  return (
    <Layout seoData={seoData} mmv3>
      <StructuredData />
      <FeaturedBanner />
      <Hero data={heroData} />
      <FormSection hubspotSource='get-in-touch-page' data={formData} />
      <MMLastSection
        theme={{
          lastSectionWrapper: styler.get("etRetailLastSection"),
        }}
        hideTicketSection
        data={lastSection}
        isMobile={isMobile}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "get-in-touch"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mmv3"}}) {
      nodes {
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          ctaUrl
          placeholder
        }
      }
    }

    allSanityGetInTouchPage {
      nodes {
        hero {
          ctaText
          heading
          video {
            asset {
              url
            }
          }
        }
        form {
          formHeading
          sectionDescription
          sectionHeading
          demoCTAText
          demoCTALoadingText
          successHeading
          successSubheading
          sectionImage {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          successImage {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default GetInTouch;
